import {Component, OnInit} from '@angular/core';
import {OrderService} from '../../../services/order.service';
import {AuthService} from '../../../services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.sass']
})
export class NotificationComponent implements OnInit {
  confirmId: any;
  allNot: any;

  constructor(
      private orderService: OrderService,
      private authService: AuthService,
      private route: ActivatedRoute,
      private router: Router,
  ) {
    this.orderService.confirmNotification.subscribe(cnt => this.getAllNotification(cnt) );
  }

  ngOnInit() {
  }

  getAllNotification(id) {
    this.confirmId = id;
    if(this.confirmId) {
      this.authService.getAllNot(this.confirmId).subscribe(data => {
        this.allNot = data.map(e => {
          e.penalty = e.status;
          e.status = this.getStatus(e.status);
          e.date = moment(e.date).format('DD/MM/YYYY HH:mm');
          return e;
        });
      });
    }
  }

  close() {
    this.orderService.userNotification(null);
    this.confirmId = null;
    this.allNot = null;
  }


  readMessage(id, id_order) {
    this.orderService.readNotification(id).subscribe(data => {
      this.router.navigate(['/account/order/' + id_order]);
      this.close();
    });
  }

  getStatus(exp) {
    switch (exp) {
      case 'payment':
        return 'До оплати';
        break;
      case 'work':
        return 'В роботі';
        break;
      case 'edit':
        return 'Вносяться правки';
        break;
      case 'done':
        return 'Виконане';
        break;
      case 'close':
        return 'Закрите';
        break;
    }
  }

}
