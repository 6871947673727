import {Component, OnInit} from '@angular/core';
import {OrderService} from '../../../services/order.service';


@Component({
  selector: 'app-delete-price',
  templateUrl: './delete-price.component.html',
  styleUrls: ['./delete-price.component.sass']
})
export class DeletePriceComponent implements OnInit {
  removeId: any;

  constructor(
      private orderService: OrderService,
  ) {
    this.orderService.onClickRemovePrice.subscribe(cnt => this.removeId = cnt);
  }

  ngOnInit() {

  }

  close() {
    this.orderService.removePrice(null);
  }

  remove() {
    this.orderService.removePriceById(this.removeId).subscribe(data => {
      this.close();
    });
  }

}
