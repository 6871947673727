import {Component, OnInit} from '@angular/core';
import {OrderService} from '../../../services/order.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-change-marker',
  templateUrl: './change-marker.component.html',
  styleUrls: ['./change-marker.component.sass']
})
export class ChangeMarkerComponent implements OnInit {
  confirmId: any;
  messages = new FormControl();

  constructor(
      private orderService: OrderService,
  ) {
    this.orderService.confirmChangeMarker.subscribe(cnt => this.defaultVal(cnt));
  }

  ngOnInit() {}

  defaultVal(cnt) {
    this.confirmId = cnt;
    this.messages.setValue(cnt.default);
  }

  close() {
    this.orderService.userChangeMarker(null, null);
    this.confirmId = null;
  }

  confirm() {
    this.orderService.sendMarkerUser({
      id_user: this.confirmId.id_user,
      marker: this.messages.value,
    }).subscribe(data => {
      this.close();
    });
  }

  onSubmit() {
    this.confirm();
  }




}
