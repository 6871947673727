import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import domain from './domain.config';

@Injectable({
    providedIn: 'root'
})
export class UploderService {

    constructor(private _http: HttpClient) {
    }

    uploadImage(url: string, data) {
        return this._http.post(`${domain}/${url}`, data, {
            reportProgress: true,
            observe: 'events'
        });
    }

    uploadFile(url: string, data) {
        return this._http.post(`${domain}/${url}`, data, {
            reportProgress: true,
            observe: 'events'
        });
    }

}
