import {Component, OnInit} from '@angular/core';
import {OrderService} from '../../../services/order.service';
import * as moment from 'moment';
import * as $ from 'jquery';

@Component({
  selector: 'app-done-work',
  templateUrl: './done-work.component.html',
  styleUrls: ['./done-work.component.sass']
})
export class DoneWorkComponent implements OnInit {
  removeId: any;

  constructor(
      private orderService: OrderService,
  ) {
    this.orderService.confirmDoneWork.subscribe(cnt => this.getDetails(cnt));
  }

  ngOnInit() {

  }

  getDetails(cnt){
    this.removeId = cnt;
  }

  close() {
    this.orderService.doneWork(null, null, null);
  }

  remove() {
    let id = this.removeId.id;
    this.removeId.element[id].done = moment().format();
    let newArr = JSON.stringify(this.removeId.element);
    this.orderService.updateDateDone(newArr, this.removeId.id_order).subscribe(data => {
      this.close();
    });
  }

}
