import {Component, OnInit} from '@angular/core';
import {OrderService} from '../../../services/order.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-add-towork',
  templateUrl: './add-towork.component.html',
  styleUrls: ['./add-towork.component.sass']
})
export class AddToworkComponent implements OnInit {
  confirmId: any;
  addPriceForm: FormGroup;

  areaConfig: any = {
    placeholder: '',
    tabsize: 2,
    height: 300,
    toolbar: [
      ['style', ['style']],
      ['misc', ['undo', 'redo']],
      ['style', ['bold', 'italic', 'underline', 'clear']],
      ['font', ['strikethrough', 'superscript', 'subscript', 'clear']],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['style0', 'ul', 'ol', 'paragraph', 'height']],
      ['insert', ['picture', 'link', 'video', 'hr']]
    ],
    fontNames: ['Lato', 'Lato Bold', 'Lato Black', 'Helvetica', 'Arial', 'Times']
  };

  constructor(
      private orderService: OrderService,
      private formBuilder: FormBuilder,
  ) {
    this.orderService.toworkOrderPrice.subscribe(cnt => this.getAll(cnt));
  }

  getAll(cnt) {
    this.confirmId = cnt;
    this.addPriceForm.patchValue({
      content: this.confirmId.content,
    });
  }

  ngOnInit() {
    this.addPriceForm = this.formBuilder.group({
      content: ['', Validators.required]
    });
  }

  close() {
    this.orderService.toworkOrder(null, null, null);
    this.confirmId = null;
  }

  get f() { return this.addPriceForm.controls; }

  confirm() {
    this.confirmId.content = this.f.content.value;
    this.orderService.todoOrderById(this.confirmId).subscribe(data => {
      this.close();
    });
  }

  onSubmit() {
    this.confirm();
  }




}
