import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from '../services/auth.service';

@Injectable()
export class ManagerGuard implements CanActivate {
constructor(
    private router: Router,
    private auth: AuthService
)
{}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (localStorage.getItem('currentManager') ) {
     return this.auth.isTokenValid(state);
  	}
    this.router.navigate(['/login']);
    return false;
  }
}
