import {Component, OnInit} from '@angular/core';
import {OrderService} from '../../../services/order.service';
import {AuthService} from '../../../services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-message-popup',
  templateUrl: './message-popup.component.html',
  styleUrls: ['./message-popup.component.sass']
})
export class MessagePopupComponent implements OnInit {
  allNot: any;
  id_user: any;

  constructor(
      private orderService: OrderService,
      private authService: AuthService,
      private route: ActivatedRoute,
      private router: Router,
  ) {
    this.orderService.confirmOpenLoadMessage.subscribe(cnt => this.getAllNotification(cnt) );
  }

  ngOnInit() {
    if (localStorage.currentUser) {
      this.id_user = JSON.parse(localStorage.currentUser).id;
      this.getAllNotification(this.id_user);
    }
  }

  getAllNotification(id) {
    if(Math.floor(id) == id || (id.id_message != null && id.id_user != null)) {
      this.authService.getNotNewsUser(id).subscribe(data => {
        this.allNot = data.length > 0 ? data[0] : [];
      });
    }
  }

  close() {
    this.orderService.userMessagePopup(null, null);
    this.allNot = null;
  }

  readNews(id) {
      this.orderService.readNewsById({
        id_news: id,
        id_user: this.id_user
      }).subscribe(data => {
        this.close();
      });
  }


  // readMessage(id, id_order) {
  //   this.orderService.readNotification(id).subscribe(data => {
  //     this.router.navigate(['/account/order/' + id_order]);
  //     this.close();
  //   });
  // }


}
