import {Component, OnInit} from '@angular/core';
import {OrderService} from '../../../services/order.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DateAdapter} from '@angular/material/core';
import * as moment from 'moment';
import * as $ from 'jquery';

@Component({
  selector: 'app-status-account',
  templateUrl: './status-account.component.html',
  styleUrls: ['./status-account.component.sass']
})
export class StatusAccountComponent implements OnInit {
  confirmId: any;
  userStatusForm: FormGroup;
  minDate = moment(new Date()).format('YYYY-MM-DD')

  constructor(
      private orderService: OrderService,
      private formBuilder: FormBuilder,
      private dateAdapter: DateAdapter<Date>,
  ) {
    this.orderService.confirmStatus.subscribe(cnt => this.confirmId = cnt);
    this.dateAdapter.setLocale('uk-UA');
  }

  ngOnInit() {
    this.userStatusForm = this.formBuilder.group({
      data_start: ['', Validators.required],
      data_end: ['', Validators.required],
      comment: ['', Validators.required]
    });
  }

  close() {
    this.orderService.userStatus(null, null, null);
    this.confirmId = null;
  }

  get f() { return this.userStatusForm.controls; }

  confirm() {
    this.confirmId.data_start = moment(this.f.data_start.value).format('YYYY/MM/DD');
    this.confirmId.data_end = moment(this.f.data_end.value).format('YYYY/MM/DD');;
    this.confirmId.comment = this.f.comment.value;
    this.orderService.sendStatus(this.confirmId).subscribe(data => {
      this.close();
    });
  }

  onSubmit() {

    if (!this.userStatusForm.valid ) {
      alert('Заповніть всі поля!');
      return;
    }
    this.confirm();
  }




}
