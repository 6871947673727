import {Component, OnInit} from '@angular/core';
import {OrderService} from '../../../services/order.service';


@Component({
  selector: 'app-delete-user',
  templateUrl: './delete-user.component.html',
  styleUrls: ['./delete-user.component.sass']
})
export class DeleteUserComponent implements OnInit {
  removeId: any;

  constructor(
      private orderService: OrderService,
  ) {
    this.orderService.onClickUser.subscribe(cnt => this.removeId = cnt);
  }

  ngOnInit() {

  }

  close() {
    this.orderService.removeUser(null);
  }

  remove() {
    this.orderService.removeUserById(this.removeId).subscribe(data => {
      this.close();
    });
  }

}
