import {Component, OnInit} from '@angular/core';
import {OrderService} from '../../../services/order.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthorService} from '../../../services/author.service';

@Component({
  selector: 'app-change-status',
  templateUrl: './change-status.component.html',
  styleUrls: ['./change-status.component.sass']
})
export class ChangeStatusComponent implements OnInit {
  confirmId: any;
  status = new FormControl();

  constructor(
      private orderService: OrderService,
  ) {
    this.orderService.confirmChangeStatus.subscribe(cnt => this.confirmId = cnt);
  }

  ngOnInit() {
  }

  close() {
    this.orderService.changeStatusOrder(null);
    this.confirmId = null;
  }

  confirm() {
    this.orderService.changeStatus({
      id: this.confirmId,
      status: this.status.value,
    }).subscribe(data => {
      this.close();
    });
  }

  onSubmit() {
    if (!this.status.value) {
      alert('Заповніть всі поля!');
      return;
    }
    this.confirm();
  }




}
