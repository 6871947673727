import {Component, OnInit} from '@angular/core';
import {OrderService} from '../../../services/order.service';


@Component({
  selector: 'app-delete-order',
  templateUrl: './delete-order.component.html',
  styleUrls: ['./delete-order.component.sass']
})
export class DeleteOrderComponent implements OnInit {
  removeId: any;
  idManager: any;

  constructor(
      private orderService: OrderService,
  ) {
    this.orderService.onClick.subscribe(cnt => this.removeId = cnt);
  }

  ngOnInit() {

  }

  close() {
    this.orderService.removeOrder(null);
  }

  remove() {
    if (localStorage.currentManager) {
      this.idManager = JSON.parse(localStorage.currentManager).id;
    }
    this.orderService.removeOrderById({
      id: this.removeId,
      manager: this.idManager ? this.idManager : 0
    }).subscribe(data => {
      this.close();
    });
  }

}
