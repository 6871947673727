import {Component, Input, OnInit} from '@angular/core';
import {OrderService} from '../../../services/order.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as $ from 'jquery';
import * as moment from 'moment';
declare var $: any;

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.sass']
})
export class CalendarComponent implements OnInit {
  @Input() data: any;
  @Input() type: any;
  @Input() open: any;
  sampleEvents: any;
  arraySample = [];
  countCalendar = 0;

  constructor(
      private orderService: OrderService,
  ) {
  }

  ngOnInit() {
    this.orderService.onClickCalendar.subscribe((data) => {
      this.arraySample = [];
      $('.monthly').remove();
      $('<div class="monthly" id="mycalendar-'+this.countCalendar+'"></div>').appendTo('.popup-monthly');
      // $('#mycalendar').html(' ');
      // @ts-ignore
      data.map(item => {
        if(item.status == "done" || item.status == "close" || item.status == 'payment'){
        }else {
          var a = moment();
          var b = moment(item.data_endCalendar);
          this.arraySample.push({
            'id': item.id,
            'name': 'Замовлення <b>' + item.id_order + '</b><br><i>' + this.getStatusOrder(item.status) + '</i>',
            'startdate': item.data_endCalendar,
            'enddate': item.data_endCalendar,
            'starttime': '20:00',
            'endtime': '20:00',
            'color': b.diff(a, 'hours')<0 ? '#ff0000' : '#00ae5a',
            'url': '/' + this.type + '/order/' + item.id
          });
          if (item.add_content.length > 0) {
            item.add_content.map(e => {
              if (!e.done) {
                let c = moment();
                var d = moment(e.startTime);
                if(e.startTime) {
                  this.arraySample.push({
                    'id': item.id,
                    'name': '' + e.title + ' <br>Замовлення <b>' + item.id_order + '</b><br><i>' + this.getStatusOrder(item.status) + '</i>',
                    'startdate': e.startTime,
                    'enddate': e.startTime,
                    'starttime': '20:00',
                    'endtime': '20:00',
                    'color': d.diff(c, 'hours') < 0 ? '#ff0000' : '#99CCCC',
                    'url': '/' + this.type + '/order/' + item.id
                  });
                }
              }
            })
          }
        }
      });
      this.sampleEvents = {
        'monthly' : this.arraySample
      };
      this.calendar(this.sampleEvents);
      this.open = true;
    });
  }

  calendar(data) {
    $('#mycalendar-'+this.countCalendar).monthly({
      mode: 'event',
      dataType: 'json',
      events: data
    });
    this.countCalendar++;
  }

  getStatusOrder(exp) {
    switch (exp) {
      case 'new':
        return 'Нове замовлення';
        break;
      case 'rated':
        return 'Замовлення оцінені автором';
        break;
      case 'work':
        return 'Замовлення у виконанні';
        break;
      case 'edit':
        return 'Вносяться правки';
        break;
      case 'done':
        return 'Виконані замовлення';
        break;
      case 'close':
        return 'Закриті замовлення';
        break;
    }
  }
}
