import {Component, OnInit} from '@angular/core';
import {OrderService} from '../../../services/order.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DateAdapter} from '@angular/material/core';

@Component({
  selector: 'app-add-review',
  templateUrl: './add-review.component.html',
  styleUrls: ['./add-review.component.sass']
})
export class AddReviewComponent implements OnInit {
  confirmId: any;
  userStatusForm: FormGroup;
  reviewCount = 0;

  constructor(
      private orderService: OrderService,
      private formBuilder: FormBuilder,
      private dateAdapter: DateAdapter<Date>,
  ) {
    this.orderService.confirmAddReview.subscribe(cnt => this.confirmId = cnt);
    this.dateAdapter.setLocale('uk-UA');
  }

  ngOnInit() {
    this.userStatusForm = this.formBuilder.group({
      review: [''],
      comment: ['', Validators.required]
    });
  }

  close() {
    this.orderService.userReview(null, null);
    this.confirmId = null;
  }

  get f() { return this.userStatusForm.controls; }

  confirm() {
    this.orderService.sendReview({
      review: this.reviewCount,
      text: this.f.comment.value,
      id_manager: this.confirmId.users,
      id_order: this.confirmId.id_order
    }).subscribe(data => {
      this.close();
    });
  }

  onSubmit() {
    if (!this.userStatusForm.valid) {
      alert('Заповніть всі поля!');
      return;
    }
    this.confirm();
  }


}
