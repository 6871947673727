import {Component, OnInit} from '@angular/core';
import {OrderService} from '../../../services/order.service';
import {AuthService} from '../../../services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import * as moment from 'moment';
import {AuthorService} from "../../../services/author.service";

@Component({
  selector: 'app-expire-plan',
  templateUrl: './expire-plan.component.html',
  styleUrls: ['./expire-plan.component.sass']
})
export class ExpirePlanComponent implements OnInit {
  confirmId: any;
  allNot = [];
  allNotRazdel = [];
  allNotIndex = [];
  allDop = [];
  allOrder = [];

  constructor(
      private orderService: OrderService,
      private authService: AuthService,
      private route: ActivatedRoute,
      private router: Router,
      private authorService: AuthorService,
  ) {
    this.orderService.confirmExpire.subscribe(cnt => this.getAllExpire(cnt) );
  }

  ngOnInit() {

  }

  getAllExpire(cnt) {
    this.confirmId = true;
    if(cnt && cnt.data.length >0) {
      this.allOrder = cnt.data.map(item => {
        item.add_content.map(e => {
          let c = moment();
          var d = moment(e.startTime);
          if (!e.done && d.diff(c, 'hours') < 0) {
            this.allNotRazdel.push(e.title);
            this.allNot.push(item);
            this.allNotIndex.push(e.see);
          } else if (e.done && moment(e.done).diff(d, 'hours') < 0) {
            this.allNotRazdel.push(e.title);
            this.allNot.push(item);
            this.allNotIndex.push(e.see);
          }
        });
        return item;
      });
    }
  }

  close() {
    this.orderService.userExpire([]);
    this.confirmId = null;
    this.allNot = [];
    this.allDop = [];
    this.allNotIndex = [];
    this.allNotRazdel = [];
    this.allOrder = [];
  }

  read(id, title){
    let element = this.allOrder.find(e=> e.id == id);
    let newdata = element.add_content.map(item=>{
      if(item.title == title){
        item.see = true;
      }
      return item;
    })
    this.orderService.updateDateDone(JSON.stringify(newdata), id).subscribe( data => {
      this.router.navigate(['manager/order/'+id]);
    } );
    console.log(newdata);
  }

}
