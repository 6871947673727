import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import { RouterModule } from '@angular/router';
import { NotfoundComponent } from './components/user/notfound/notfound.component';
import { AppComponent } from './app.component';
import { SharedModule } from './components/shared/shared.module';
import {TransferHttpCacheModule} from '@nguniversal/common';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { UserGuard } from './guards/user.guard';
import { AdminGuard } from "./guards/admin.guard";
import { ManagerGuard } from "./guards/manager.guard";

@NgModule({
  declarations: [
    AppComponent,
    NotfoundComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'my-app'}),
    RouterModule.forRoot([
      {
        path: "account",
        loadChildren: "./components/account/account.module#AccountModule"
      },
      { path: '', loadChildren: './components/user/user.module#UserModule'},
      { path: 'admin', loadChildren: './components/admin/admin.module#AdminModule'},
      { path: 'manager', loadChildren: './components/manager/manager.module#ManagerModule'},
      { path: '404', component: NotfoundComponent },
      { path: '**', redirectTo: '/404' }
    ]),
    SharedModule,
    TransferHttpCacheModule,
    HttpClientModule,
    BrowserAnimationsModule
   ],
  providers: [
    UserGuard,
    AdminGuard,
    ManagerGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
