import {Injectable, EventEmitter} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { map } from 'rxjs/operators/';
import {Router} from '@angular/router';
import domain from './domain.config';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
      private _http: HttpClient,
      private router: Router) {
  }
  logoutEmitter: EventEmitter<any> = new EventEmitter();

  isTokenValid(state): any {
    let token = 'token';
    let id = 0;
    let type = 0;
    if (localStorage.currentManager) {
      token = JSON.parse(localStorage.getItem('currentManager')).token;
      id = JSON.parse(localStorage.getItem('currentManager')).id;
      type = JSON.parse(localStorage.getItem('currentManager')).type;
    }
    const prom = new Promise((resolve, reject) => {
      this._http.post(`${domain}/user/checkTokenManager`, {id, token, type}).subscribe(data => {
            if (data) {
              resolve(true);
            }
            resolve(false);
          },
          err => {
            console.log('error: ', err);
          });
    });

    return prom.then((data) => {
      if (!data) {
        this.router.navigate(['login'], {queryParams: {returnUrl: state.url}});
      }
      return data;
    });
  }

  loginAdmin(email: string, password: string) {
    return this._http.post<any>(`${domain}/admin/login`, {email, password});
  }

  loginUser(email: string, password: string) {
    return this._http.post<any>(`${domain}/user/login/`, {email, password});
  }

  registerUser(name: string, lastname: string, phone: any, email: string, password: string) {
    return this._http.post<any>(`${domain}/user/register/`, {name, lastname, phone, email, password, domain});
  }

  sendLogin(data) {
    return this._http.post<any>(`${domain}/user/sendLogin`, { data });
  }

  getHash(data) {
    return this._http.post<any>(`${domain}/user/getHash`, { data });
  }

  changePassword(data) {
    return this._http.post<any>(`${domain}/user/changePassword`, { data });
  }

  updateUser(data) {
    return this._http.post<any>(`${domain}/user/updateUser`, { data });
  }

  getUserId(id) {
    return this._http.post<any>(`${domain}/user/getUserId`, {id});
  }

  getUserIdFull(id) {
    return this._http.post<any>(`${domain}/user/getUserIdFull`, {id});
  }

  getAllMessages(id) {
    return this._http.post<any>(`${domain}/user/getAllNoReadMessage`, {id});
  }

  getAllNotification(id) {
    return this._http.post<any>(`${domain}/user/getAllNotification`, {id});
  }

  getAllNotificationAdmin(id) {
    return this._http.post<any>(`${domain}/user/getAllNotificationAdmin`, {id});
  }

  getAllNot(id) {
    return this._http.post<any>(`${domain}/user/getAllNot`, {id});
  }

  getOrderRecomended(id) {
    return this._http.post<any>(`${domain}/user/getOrderRecomended`, {id});
  }

  getNotNewsUser(id) {
    return this._http.post<any>(`${domain}/user/getNotNewsUser`, {id});
  }
}
