import {Component, OnInit} from '@angular/core';
import {OrderService} from '../../../services/order.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DateAdapter} from '@angular/material/core';
import * as moment from 'moment';
import * as $ from 'jquery';
import {MatTableDataSource} from '@angular/material';
import {AuthorService} from '../../../services/author.service';
import {json} from 'express';

@Component({
  selector: 'app-confirm-account',
  templateUrl: './confirm-add.component.html',
  styleUrls: ['./confirm-add.component.sass']
})
export class ConfirmAddComponent implements OnInit {
  confirmId: any;
  toppings = new FormControl();
  messages = new FormControl();
  toppingList: any;

  constructor(
      private orderService: OrderService,
      private formBuilder: FormBuilder,
      private dateAdapter: DateAdapter<Date>,
      private authorService: AuthorService
  ) {
    this.orderService.confirmAddStatus.subscribe(cnt => this.confirmId = cnt);
  }

  ngOnInit() {
    this.authorService.getAllAuthor().subscribe(res => {
      this.toppingList = res;
    });
  }

  close() {
    this.orderService.userConfirmOrder(null, null);
    this.confirmId = null;
  }

  confirm() {
    this.orderService.sendOrderUsers({
      id_order: this.confirmId.id_order,
      users: this.toppings.value,
      messages: this.messages.value,
    }).subscribe(data => {
      this.close();
    });
  }

  onSubmit() {
    if (!this.toppings.value) {
      alert('Заповніть всі поля!');
      return;
    }
    this.confirm();
  }




}
