import {Component, OnInit} from '@angular/core';
import {OrderService} from '../../../services/order.service';


@Component({
  selector: 'app-delete-spec',
  templateUrl: './delete-spec.component.html',
  styleUrls: ['./delete-spec.component.sass']
})
export class DeleteSpecComponent implements OnInit {
  removeId: any;
  idManager: any;

  constructor(
      private orderService: OrderService,
  ) {
    this.orderService.onRemoveSpec.subscribe(cnt => this.removeId = cnt);
  }

  ngOnInit() {

  }

  close() {
    this.orderService.removeSpec(null);
  }

  remove() {
    if (localStorage.currentManager) {
      this.idManager = JSON.parse(localStorage.currentManager).id;
    }
    this.orderService.removeSpecById({
      id: this.removeId,
      manager: this.idManager ? this.idManager : 0
    }).subscribe(data => {
      this.orderService.removeSpecReturn(this.removeId);
      this.close();
    });
  }

}
