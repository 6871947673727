import {Component, OnInit} from '@angular/core';
import {OrderService} from '../../../services/order.service';


@Component({
  selector: 'app-confirm-order',
  templateUrl: './confirm-order.component.html',
  styleUrls: ['./confirm-order.component.sass']
})
export class ConfirmOrderComponent implements OnInit {
  confirmId: any;

  constructor(
      private orderService: OrderService,
  ) {
    this.orderService.confirmOrder.subscribe(cnt => this.confirmId = cnt);
  }

  ngOnInit() {

  }

  close() {
    this.orderService.orderConfirm(null);
  }

  confirm() {
    this.orderService.confirmOrderById(this.confirmId).subscribe(data => {
      this.close();
    });
  }

}
