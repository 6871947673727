import {Component, OnInit} from '@angular/core';
import {OrderService} from '../../../services/order.service';
import {AuthService} from '../../../services/auth.service';
import {AuthorService} from '../../../services/author.service';
import {ActivatedRoute, Router} from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.sass']
})
export class NewsComponent implements OnInit {
  confirmId: any;
  allNot: any;

  constructor(
      private orderService: OrderService,
      private authService: AuthService,
      private authorService: AuthorService,
      private route: ActivatedRoute,
      private router: Router,
  ) {
    this.orderService.confirmNews.subscribe(cnt => this.getAllNotification(cnt) );
  }

  ngOnInit() {
  }

  getAllNotification(id) {
    this.confirmId = id;
    this.authorService.getNewsAll().subscribe(res => {
      this.allNot = res.map(e => {
        e.date = moment(e.date).format('DD/MM/YYYY HH:mm');
        return e;
      });
    });
  }

  close() {
    this.orderService.userNews(null);
    this.confirmId = null;
    this.allNot = null;
  }


  // readMessage(id) {
  //   this.orderService.readNotification(id).subscribe(data => {
  //     this.router.navigate(['/account/order/' + id]);
  //     this.close();
  //   });
  // }


}
