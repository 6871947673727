import {Component, OnInit} from '@angular/core';
import {OrderService} from '../../../services/order.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-add-done',
  templateUrl: './add-done.component.html',
  styleUrls: ['./add-done.component.sass']
})
export class AddDoneComponent implements OnInit {
  confirmId: any;
  dateEndForm: FormGroup;

  constructor(
      private orderService: OrderService,
      private formBuilder: FormBuilder,
  ) {
    this.orderService.dateEnd.subscribe(cnt => this.confirmId = cnt);
  }

  ngOnInit() {
    this.dateEndForm = this.formBuilder.group({
      date: ['', Validators.required]
    });
  }

  close() {
    this.orderService.dateEndFunc(null, null);
    this.confirmId = null;
  }

  get f() { return this.dateEndForm.controls; }

  confirm() {
    this.confirmId.date = moment(this.f.date.value).locale('ua');
    this.orderService.dataEndOrderById(this.confirmId).subscribe(data => {
      this.close();
    });
  }

  onSubmit() {
    this.confirm();
  }




}
