import {Component, OnInit} from '@angular/core';
import {OrderService} from '../../../services/order.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DateAdapter} from '@angular/material/core';
import {AuthorService} from '../../../services/author.service';

@Component({
  selector: 'app-send-account',
  templateUrl: './send-message.component.html',
  styleUrls: ['./send-message.component.sass']
})
export class SendMessageComponent implements OnInit {
  confirmId: any;
  userStatusForm: FormGroup;
  toppings = new FormControl();
  toppingList: any;

  constructor(
      private orderService: OrderService,
      private formBuilder: FormBuilder,
      private dateAdapter: DateAdapter<Date>,
      private authorService: AuthorService
  ) {
    this.orderService.sendMessStatus.subscribe(cnt => this.confirmId = cnt);
    this.dateAdapter.setLocale('uk-UA');
  }

  ngOnInit() {
    this.authorService.getAllAuthor().subscribe(res => {
      this.toppingList = res;
    });
    this.userStatusForm = this.formBuilder.group({
      title: ['', Validators.required],
      comment: ['', Validators.required]
    });
  }

  close() {
    this.orderService.userSend(null, null);
    this.confirmId = null;
  }

  get f() { return this.userStatusForm.controls; }

  confirm() {
    this.orderService.sendMess({
      title: this.f.title.value,
      text: this.f.comment.value,
      id_user: this.toppings.value,
      id_manager: this.confirmId.id_user
    }).subscribe(data => {
      this.close();
    });
  }

  onSubmit() {
    if (!this.userStatusForm.valid || !this.toppings.value) {
      alert('Заповніть всі поля!');
      return;
    }
    this.confirm();
  }




}
