import {Component, OnInit} from '@angular/core';
import {OrderService} from '../../../services/order.service';


@Component({
  selector: 'app-close-order',
  templateUrl: './close-order.component.html',
  styleUrls: ['./close-order.component.sass']
})
export class CloseOrderComponent implements OnInit {
  removeId: any;

  constructor(
      private orderService: OrderService,
  ) {
    this.orderService.onClickClose.subscribe(cnt => this.removeId = cnt);
  }

  ngOnInit() {

  }

  close() {
    this.orderService.closeOrder(null);
  }

  remove() {
    this.orderService.closeOrderById(this.removeId).subscribe(data => {
      this.close();
    });
  }

}
