import {Component, OnInit} from '@angular/core';
import {OrderService} from '../../../services/order.service';
import {AuthorService} from '../../../services/author.service';
import {AuthService} from '../../../services/auth.service';


@Component({
  selector: 'app-change-user',
  templateUrl: './change-user.component.html',
  styleUrls: ['./change-user.component.sass']
})
export class ChangeUserComponent implements OnInit {
  removeId: any;
  userId: any;
  userType: any;

  constructor(
      private orderService: OrderService,
      private authorService: AuthorService,
      private authService: AuthService
  ) {
    this.orderService.onChangeUser.subscribe(cnt => this.getUser(cnt));
  }

  ngOnInit() {

  }

  getUser(id) {
    this.removeId = id;
    if (id) {
      this.authService.getUserIdFull(id).subscribe(data => {
        this.userId = data;
        this.userType = data.type == 1 ? 2 : 1;
      });
    }
  }

  close() {
    this.orderService.changeTypeUser(null);
  }

  remove() {
    this.orderService.changeUserType(this.removeId).subscribe(data => {
      this.close();
    });
  }

}
