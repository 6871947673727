import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { RouterModule } from '@angular/router';
import { AlertModalComponent } from './alert-modal/alert-modal.component';
import {DeleteOrderComponent} from './delete-order/delete-order.component';
import {DeleteMessageComponent} from './delete-message/delete-message.component';
import {ConfirmOrderComponent} from './confirm-order/confirm-order.component';
import {PriceOrderComponent} from './price-order/price-order.component';
import {
    MatButtonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatSidenavModule,
    MatStepperModule,
    MatTableModule,
    MatToolbarModule,
    MatDialogModule, MatSelectModule
} from '@angular/material';
import {ReactiveFormsModule} from '@angular/forms';
import {StatusAccountComponent} from './status-account/status-account.component';
import {ConfirmAddComponent} from './confirm-add/confirm-add.component';
import {NotificationComponent} from './notification/notification.component';
import {SendMessageComponent} from './send-message/send-message.component';
import {SendMessageAccountComponent} from './send-message-account/send-message-account.component';
import {DeleteMessageSingleComponent} from './delete-message-single/delete-message-single.component';
import {AddReviewComponent} from './add-review/add-review.component';
import {DeleteNewsComponent} from './delete-news/delete-news.component';
import {NewsComponent} from './news/news.component';
import {DeleteUserComponent} from './delete-user/delete-user.component';
import {CloseOrderComponent} from './close-order/close-order.component';
import {ChangeStatusComponent} from './change-status/change-status.component';
import {ChangeMarkerComponent} from './change-marker/change-marker.component';
import {AddPaymentComponent} from './add-payment/add-payment.component';
import {AddPenaltyComponent} from './add-penalty/add-penalty.component';
import {AddToworkComponent} from './add-towork/add-towork.component';
import {NgxSummernoteModule} from 'ngx-summernote';
import {AddFileComponent} from './add-file/add-file.component';
import {ChangeUserComponent} from './change-user/change-usercomponent';
import {MessagePopupComponent} from './message-popup/message-popup.component';
import {DeletePriceComponent} from './delete-price/delete-price.component';
import {AddPriceOrderUserComponent} from './add-price-order-user/add-price-order-user.component';
import {AddPriceOrderPenaltyComponent} from './add-price-order-penalty/add-price-order-penalty.component';
import {CalendarComponent} from './calendar/calendar.component';
import {DoneWorkComponent} from "./done-work/done-work.component";
import {ExpirePlanComponent} from "./expire-plan/expire-plan.component";
import {AddDoneComponent} from "./add-done/add-done.component";
import {NotificationOrderComponent} from "./notification-order/notification-order.component";
import {NotificationAdminComponent} from './notification-admin/notification-admin.component';
import {SendMessageEmailComponent} from './send-message-email/send-message-email.component';
import {DeleteSpecComponent} from './delete-spec/delete-spec.component';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatStepperModule,
        MatInputModule,
        MatFormFieldModule,
        MatRippleModule,
        MatToolbarModule,
        MatTableModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        ReactiveFormsModule,
        MatSelectModule,
        NgxSummernoteModule
    ],
  declarations: [
  FooterComponent,
  HeaderComponent,
  AlertModalComponent,
  DeleteOrderComponent,
  DeleteMessageComponent,
  ConfirmOrderComponent,
  PriceOrderComponent,
  StatusAccountComponent,
  ConfirmAddComponent,
  NotificationComponent,
  SendMessageComponent,
  SendMessageAccountComponent,
  DeleteMessageSingleComponent,
  AddReviewComponent,
  DeleteNewsComponent,
  NewsComponent,
  DeleteUserComponent,
  CloseOrderComponent,
  ChangeStatusComponent,
  ChangeMarkerComponent,
  AddPaymentComponent,
  AddPenaltyComponent,
  AddToworkComponent,
  AddFileComponent,
  ChangeUserComponent,
  MessagePopupComponent,
  DeletePriceComponent,
  AddPriceOrderUserComponent,
  AddPriceOrderPenaltyComponent,
  CalendarComponent,
  DoneWorkComponent,
  ExpirePlanComponent,
  AddDoneComponent,
  NotificationOrderComponent,
  NotificationAdminComponent,
  SendMessageEmailComponent,
  DeleteSpecComponent

  ],
    exports: [
        FooterComponent,
        HeaderComponent,
        DeleteOrderComponent,
        DeleteMessageComponent,
        ConfirmOrderComponent,
        PriceOrderComponent,
        StatusAccountComponent,
        ConfirmAddComponent,
        NotificationComponent,
        SendMessageComponent,
        SendMessageAccountComponent,
        DeleteMessageSingleComponent,
        AddReviewComponent,
        DeleteNewsComponent,
        NewsComponent,
        DeleteUserComponent,
        CloseOrderComponent,
        ChangeStatusComponent,
        ChangeMarkerComponent,
        AddPaymentComponent,
        AddPenaltyComponent,
        AddToworkComponent,
        AddFileComponent,
        ChangeUserComponent,
        MessagePopupComponent,
        DeletePriceComponent,
        AddPriceOrderUserComponent,
        AddPriceOrderPenaltyComponent,
        CalendarComponent,
        DoneWorkComponent,
        ExpirePlanComponent,
        AddDoneComponent,
        NotificationOrderComponent,
        NotificationAdminComponent,
        SendMessageEmailComponent,
        DeleteSpecComponent
    ]
})
export class SharedModule { }
